import axios, { AxiosResponse } from 'axios'
import { AddActivityLogReqDetails } from '@/modules/api/add-activity-log/model'

const addOrgActivityLog = async ({
  data,
}: {
  data: { action: String; message: String }
}): Promise<any> => {
  const param: any = { ...data }
  param.subjectType = 'ORGANIZATION'
  addActivityLog(param)
}

const addUserActivityLog = async ({
  data,
}: {
  data: { action: String; message: String }
}): Promise<any> => {
  const param: any = { ...data }
  param.subjectType = 'USER_MANAGEMENT'
  await addActivityLog(param)
}

const addActivityLog = async (data: AddActivityLogReqDetails): Promise<any> => {
  const query = JSON.stringify({
    subjectType: data.subjectType,
    action: data.action,
    message: data.message,
    properties: data.properties,
  })
  const headers = { 'content-type': 'application/json' }

  const response: AxiosResponse<any> = await axios.post(
    '/api/add-activity-log',
    query,
    {
      headers,
    }
  )
  return response
}

const service = {
  addOrgActivityLog,
  addUserActivityLog,
}

export default service
