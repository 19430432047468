import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { RegionPickerProps } from '@/components/RegionPicker/RegionPicker.model'
import styles from '@/components/RegionPicker/regionPicker.module.scss'
import { getCookies, setCookie } from 'cookies-next'
import { setExpirationDays } from '@/utils/cookies/cookies'
import router from 'next/router'

const RegionPicker = ({
  options,
  menuIsOpen,
  classNamePrefix,
  handleMenuOpenCallback,
  handleMenuCloseCallback,
}: RegionPickerProps) => {
  const { locale, countryCode } = getCookies()
  const [selectedOption, setSelectedOption] = useState(countryCode || 'US')
  const [defaultValue, setDefaultValue] = useState([])

  useEffect(() => {
    if (options?.length > 0) {
      const filterList = options?.map(
        (match: any) =>
          Object.keys(match.options).length &&
          match.options.filter((option: any) => option.value === selectedOption)
      )
      const defaultVal: any = [...filterList[0], ...filterList[1]]
      setDefaultValue(defaultVal)
    }
  }, [locale])

  const handleTypeSelect = (e: any) => {
    const selectedCountry = e.value
    setSelectedOption(selectedCountry)

    const customLocale = 'en-' + selectedCountry.toLowerCase()

    setCookie('locale', customLocale , {
      expires: setExpirationDays(90),
    })

    setCookie('countryCode', selectedCountry, {
      expires: setExpirationDays(90),
    })

    router.push('/').then(() => {
      window.location.reload()
    })
  }

  const selectorStyles = {
    container: (base: any, state: any) => ({
      ...base,
      padding: 0,
      border: 'none',
      borderRadius: 0,
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      cursor: 'pointer',
      textAlign: 'left',
      border: 0,
      borderRadius: '0.25rem',
      boxShadow: 'none',
      outline: state.isFocused ? 'rgb(10, 65, 197) solid 2px !important' : 0,
      outlineOffset: state.isFocused ? '1px' : 0,
    }),
    option: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
      textAlign: 'left',
      boxShadow: 'none',
    }),
  }

  return (
    <>
      {options?.length > 0 && (
        <div
          className={
            classNamePrefix
              ? styles.regionPickerMobileWrapper
              : styles.regionPickerWrapper
          }
        >
          <Select
            isSearchable={false}
            options={options}
            aria-label={'Select Country'}
            formatOptionLabel={(options: any) => (
              <div
                className={
                  menuIsOpen ? styles.countryOptionOpen : styles.countryOption
                }
              >
                <img
                  src={process.env.CONTENT_DOMAIN + options.image}
                  alt="country-image"
                  width="24"
                  height="16"
                />
                <span>{options.label}</span>
              </div>
            )}
            onChange={handleTypeSelect}
            onMenuOpen={handleMenuOpenCallback}
            onMenuClose={handleMenuCloseCallback}
            styles={selectorStyles}
            value={defaultValue}
            label="Single select"
            classNamePrefix={classNamePrefix || 'filter'}
            menuIsOpen={menuIsOpen || false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
    </>
  )
}

export default RegionPicker
