import { useMutation } from '@tanstack/react-query'
import service from './services'
import { useServicesCacheUtil } from './services-cache.util'
import { useRouter } from 'next/router'

export const useServicesMutation = () => {
  const router = useRouter()
  const servicesCacheUtil = useServicesCacheUtil()

  const addOrgActivityLogMutation = useMutation({
    mutationFn: service.addOrgActivityLog,
    onSuccess: (data: any) => {
      servicesCacheUtil.invalidateAddActivityLogInCache()
    },
  })

  const addUserActivityLogMutation = useMutation({
    mutationFn: service.addUserActivityLog,
    onSuccess: (data: any) => {
      servicesCacheUtil.invalidateAddActivityLogInCache()
    },
  })

  return {
    addOrgActivityLogMutation,
    addUserActivityLogMutation,
  }
}
