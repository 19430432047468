import { useRouter } from 'next/router'

const markdownSvgSelector = '[class*="documentPageMDContent"] svg',
  markdownSvgAriaLabel = 'Mermaid SVG Image',
  searchInterval = 500,
  domSearchLimit = 20

let svgSearchInterval: any, svgSearchIndex: any

function addAriaLabelToSvgUpdates() {
  let elements = document.querySelectorAll(markdownSvgSelector)

  for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
    elements[elementIndex].setAttribute('aria-label', markdownSvgAriaLabel)
  }

  svgSearchIndex++

  if (svgSearchIndex > domSearchLimit) {
    clearInterval(svgSearchInterval)
  }
}

export const titleTagSuffix = 'Developer Portal'

export function addAriaLabelToSvg() {
  svgSearchIndex = 0

  svgSearchInterval = setInterval(addAriaLabelToSvgUpdates, searchInterval)

  return true
}

export function getTitleFromRoute() {
  let router = useRouter()

  switch (router.asPath) {
    case '/accept-terms-of-use':
      return `Accept terms of use | ${titleTagSuffix}`

    case '/profile':
      return `Profile | ${titleTagSuffix}`

    case '/support/create':
      return `Support | ${titleTagSuffix}`

    case '/credentials':
      return `Credentials | ${titleTagSuffix}`

    case '/forgot-username':
      return `Forgot username | ${titleTagSuffix}`

    case '/forgot_password':
      return `Forgot password | ${titleTagSuffix}`

    case '/profile/add-user':
      return `Add user | ${titleTagSuffix}`

    default:
      return titleTagSuffix
  }
}
