import { useQueryClient } from '@tanstack/react-query'

export const useServicesCacheUtil = () => {
  const queryClient = useQueryClient()
  const invalidateAddActivityLogInCache = () => {
    queryClient.invalidateQueries({ queryKey: ['ADD-ACTIVITY-LOG'] })
  }

  return {
    invalidateAddActivityLogInCache,
  }
}
