export const formatProductorTagName = (name?: string) => {
    if(name) {
        return name
        .replace(/-/g, ' ')
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    } else {
        return ''
    }
}

export const convertStringIntoCamelCase = (str: string) => {
    const a = str
      ?.toLowerCase()
      .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))

    return a?.substring(0, 1).toLowerCase() + a?.substring(1)
}
